<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">绩效管理软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        提供绩效管理过程的数字化转营，使绩效信息更及时
        <br />
        工作安排自动滚动。
        <br />
        有强大的后台支持，即日京公司辅助客户建立的整套管理体系和标准。
        <br />
        MOB（工作点分解），关键点控制 。
        <br />
        经验库和案例库分享。
        <br />
        可使普通员工都能胜任绩效检查员的工作。
        <br />
        具有自动跟踪、提示和上报功能。
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        新员工上岗可迅速胜任工作。
        <br />
        可与企业绩效考核制度结合，并提供数据支持。
      </p>
      <br />
      <p class="PTitle">我们公司的解决方案：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/73.png"
        class="SonImgW"
      />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>